import React from 'react';
import './Contact.css'
import { FaEnvelopeSquare, FaPhoneAlt, FaBurn } from 'react-icons/fa'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Contact = () => {
    return (
        <div >
            <div className="hero min-h-screen bg-base-200 mt-40">
                <div className="hero-content flex-col lg:flex-row-reverse">
                    <div className="text-center lg:text-left">
                        <h1 className="text-5xl font-bold"> Direct Contact </h1>
                        <p className="py-6">Please fill up the form and contact us directly. </p>
                    </div>
                    <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
                        <div className="card-body">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Name</span>
                                </label>
                                <input type="text" placeholder="Input YOur full Name" className="input input-bordered" />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Email</span>
                                </label>
                                <input type="text" placeholder="Input Your E-mail" className="input input-bordered" />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Country</span>
                                </label>
                                <input type="text" placeholder="Input your Country Name" className="input input-bordered" />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Your Message</span>
                                </label>
                                <textarea placeholder="Your message" className="textarea textarea-bordered textarea-md w-full max-w-xs" ></textarea>
                            </div>

                            <div className="form-control mt-6">
                                <button className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 m-10  '>

                <div className="card w-96 glass bg-base-100 shadow-xl">
                    <div className="card-body mx-auto">
                        <FaPhoneAlt className='text-4xl center m-auto'></FaPhoneAlt>
                        <h2 className="card-title m-auto">Contact By Phone</h2>
                        <p className='my-0 '>CEO: <a className='' href="tel:+8801610751185">+8801610751185</a></p>
                        <p className='my-0'>COO: <a href="tel:+8801313935456">+8801313935456</a></p>
                        <p className='my-0'>CMO: <a href="tel:+8801725787120">+8801725787120</a></p>
                    </div>
                </div>
                <div className="card w-96 glass bg-base-100 shadow-xl my-5">
                    <div className="card-body mx-auto ">
                        <FaEnvelopeSquare className='text-4xl center m-auto bg-primary'></FaEnvelopeSquare>
                        <h2 className="card-title m-auto">Contact By Email</h2>
                        <p className='m-auto'><a href="mailto:aklima@cocovillagebd.com">aklima@cocovillagebd.com</a></p>
                        <p className='m-auto'><a href="mailto:sabujislam32195@gmail.com">sabujislam@cocovillagegmail.com</a></p>
                        <p className='m-auto'><a href="mailto:rohman@cocovillagebd.com">director@cocovillagebd.com</a></p>
                    </div>
                </div>
                <div className="card w-96 glass bg-base-100 shadow-xl">
                    <div className="card-body mx-auto">
                        <FaBurn className='text-4xl center m-auto rotate-180'></FaBurn>
                        <h2 className="card-title m-auto">Come To See Us</h2>
                        <p className='m-auto'>122/A Munda</p>
                        <p className='m-auto'>UttarKhan, Uttara,Dhaka-1230</p>
                        <p className='m-auto'>Bangladesh</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;