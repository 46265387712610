import React from 'react';
// import img1 from '../../../Images/Products/Jute-products/bag.jpg'
import img2 from '../../../Images/Products/Jute-products/basket.jpg'
import img3 from '../../../Images/Products/Jute-products/floorMat (2).jpg'
import img4 from '../../../Images/Products/Jute-products/floorMat.jpg'
import img5 from '../../../Images/Products/Jute-products/marcrams.jpg'
import img6 from '../../../Images/Products/bedding/bedding1.jpg'
import img7 from '../../../Images/Products/bedding/bedding10.jpg'
import img8 from '../../../Images/Products/bedding/bedding11.jpg'
import img9 from '../../../Images/Products/bedding/bedding12.jpg'
import img10 from '../../../Images/Products/bedding/bedding14.jpg'
import img11 from '../../../Images/Products/seagrass/grass1.jpg'
import img12 from '../../../Images/Products/seagrass/grass2.jpg'
import img13 from '../../../Images/Products/seagrass/grass3.jpg'
import img14 from '../../../Images/Products/seagrass/grass4.jpg'
import img15 from '../../../Images/Products/seagrass/grass5.jpg'
import HomeProduct from './HomeProduct';



const homeProductsItems = [
    
    {
        image: img2
    },
    {
        image: img3
    },
    {
        image: img4
    },
    {
        image: img5
    },
    {
        image: img6
    },
    {
        image: img7
    },
    {
        image: img8
    },
    {
        image: img9
    },
    {
        image: img10
    },
    {
        image: img11
    },
    {
        image: img12
    },
    {
        image: img13
    },
    {
        image: img14
    },
    {
        image: img15
    },

]


const HomeProducts = () => {
    return (
        <div  className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 m-10 gap-10'>
            {
                homeProductsItems.map(homeProduct => <HomeProduct
                    key={homeProduct.image}
                    homeProduct={homeProduct}
                ></HomeProduct>)
            }


            
        </div>
    );
};

export default HomeProducts;