import React from 'react';

const ProductCard = ({ juteProduct }) => {
    const { name, img } = juteProduct
    return (
        <div className='m-10'>
            <div>
                <img className='h-[350px] w-[100%]' src={img} alt="" />
            </div>
            <h2 className='text-center text-2xl font-bold '>{name}</h2>
            

        </div>
    );
};

export default ProductCard;