import { createBrowserRouter } from "react-router-dom";
import Main from "../../LayOut/Main";
import About from "../../Pages/About/About";
import Home from "../../Pages/Home/Home/Home";
import LogIn from "../../Pages/LogIn/LogIn";
import SignUp from "../../Pages/SignUp/SignUp";
import Contact from "../../Pages/Contact/Contact"
import TopTeam from "../../Pages/About/TopTeam";
import FAQ from "../../Shared/Footer/FAQ/FAQ";
import TermsAndCondition from "../../Shared/Footer/TremsAndCondition/TermsAndCondition";
import AllProducts from "../../Pages/Product/AllProducts";
import Beddings from "../../Pages/Product/Beddings";
import SeaGrass from "../../Pages/Product/SeaGrass";

const router= createBrowserRouter([
    {
      path:'/',
      element:<Main></Main>,
      children:[
        {
            path:'/',
            element:<Home></Home>
        },
        {
            path:'/about',
            element:<About></About>
        },
        {
          path:'/login',
          element:<LogIn></LogIn>
        },
        {
          path:'/signup',
          element:<SignUp></SignUp>
        },
        {
          path:'/contact',
          element:<Contact></Contact>
        },
        {
          path:'/topteam',
          element:<TopTeam></TopTeam>
        }
        ,{
          path:'/faq',
          element:<FAQ></FAQ>
        },
        {
          path:'/importance',
          element:<TermsAndCondition></TermsAndCondition>
        },
        {
          path:'/products',
          element:<AllProducts></AllProducts>
        },
        {
          path:'/seagrass',
          element:<SeaGrass></SeaGrass>
        },
        {
          path:'/bedding',
          element:<Beddings></Beddings>
        }
      ]
    }
  ])



  export default router;