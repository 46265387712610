import React from 'react';
import sabuj from '../../Images/TeamImages/sabuj.jpg'
import rohoman from '../../Images/TeamImages/rohoman.jpg'
import akli from '../../Images/TeamImages/akli.jpg'



const TopTeam = () => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-40'>
            <div>
                <img src={akli} class="w-[150px] h-[150px] rounded-full d-block mx-auto" alt="" />
                <h2 className='text-center text-2xl font-bold'>CEO</h2>
                <p className='text-justify px-10 py-5'><span className='text-xl text-green-700'>Ms. Akalima Akhtar, CEO (Chief Executive Officer)</span> has immense experience in this handicraft and home textile sector as she has been in this business for almost 10 years.  She has created her unique position due to her innovative ideas, leadership skills, positive mindset, learning attitude, international work experience, hard work, dedication, adaptability, integrity and customer centric practices.  Ms. Akalima Aktar is very strong in relationship management which is one of the principles of quality management system.  She has good contacts with more than 10 customers all over the world.  She has established herself as a reliable communication person where customers find various convictions in her words.  She is very experienced in cost management methods.  She has personal connections with many of her colleagues that have placed her at various heights. B.S.S graduate from National University, She believes in participatory decision making.
                    She has attended various trainings at home and abroad which has helped her to work as a good administrator.  Coke Village BD staff   feel honoured.</p>
            </div>
            <div>
                <img src={sabuj} class="w-[150px] h-[150px] rounded-full d-block mx-auto" alt="" />
                <h2 className='text-center text-2xl font-bold'>COO</h2>
                <p className='text-justify px-10 py-5'><span className='text-xl text-green-700'>Md Sabuj Islam (Chief Operating Officer)</span>
                    ,is a very dynamic personality who has established his position very strong in this business  due to his
                    strategic planning, competency, deliberate decision-making capability, proactive approach, honesty, professionalism and hard
                    work.  He has a very good capacity to predict, especially
                    in this handicrafts business sector. He always tries to decide in advance, which is very basic of planning and the first part
                    of management.. He has participated in many workshops and performed as guest speaker organized by public and private organizations.</p>
                    </div>
                <div>
                    <img src={rohoman} class="w-[150px] h-[150px] rounded-full d-block mx-auto" alt="" />
                    <h2 className='text-center text-2xl font-bold'>CMO</h2>
                    <p className='text-justify px-10 py-5'><span className='text-xl text-green-700'>Mr. Rahman Mandal Sabuj, CMO (Chief Marking Officer)</span> is a very dynamic personality who has made his position very strong in this business for more than 12 years due to his strategic planning, expertise, deliberate decision making ability, proactive approach, integrity.  Professionalism and hard work.  Worked in many textile companies in the country and have more work experience.  He has very good powers of prediction, especially in this handicraft and bedding line business sector.  He always tries to take decisions in advance, which is very basic in planning and the first part of management.  Due to his timely and proactive decision, Coco Village BD has expanded its activities in different parts of Bangladesh through which many unemployed people got the opportunity to work and explore themselves.  As a result the overall socio-economic condition has improved.  Due to his personal intervention, Coco Village BD has set up compliant factories and production centers at various locations in Bangladesh where a large number of foreign clients visit and share their innovative ideas almost every month.  Mr. Sobuj  is well versed in  cost management systems.  As a result of his direct involvement, immense hard work and continuous efforts, all kinds of factory expansion activities and departmental development are progressing very smoothly.  Mr. Sobuj  is a good organizer.  He is involved in many social organizations with the conviction to help the deprived people. 
                         He has participated in many workshops and acted as guest speaker organized by government and private organizations.</p>
                </div>


            </div>
            );
};

            export default TopTeam;