import React from 'react';
import basket from '../../Images/Products/Jute-products/basket.jpg'
// import bag from '../../Images/Products/Jute-products/bag.jpg'
import placeMat from '../../Images/Products/Jute-products/floorMat (2).jpg'
import floorMat from '../../Images/Products/Jute-products/floorMat.jpg'
import poufs from '../../Images/Products/Jute-products/poufs.jpg'
import macrames from '../../Images/Products/Jute-products/marcrams.jpg'
import ProductCard from './ProductCard';

const AllProducts = () => {

const juteProducts=[
    {
        id:'1',
        name:'Basket',
        img:basket
    },
    
    {
        id:'3',
        name:'Floor Mat',
        img:floorMat
    },
    {
        id:'4',
        name:'Place Mat',
        img:placeMat
    },
    {
        id:'5',
        name:'Poufs',
        img:poufs
    },
    {
        id:'6',
        name:'Macrames',
        img:macrames
    },
]


    return (
        <div className='mt-40 mx-auto'>
            <h2 className='text-3xl text-center font-bold'>PRODUCTS GALLERY</h2>
                <h3 className='text-2xl m-10 font-bold'>Jute Products</h3>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 m-10 gap-10'>
                    {
                        juteProducts.map(juteProduct=><ProductCard 
                        key={juteProduct.id}
                        juteProduct={juteProduct}
                        ></ProductCard>)
                    }
                </div>
            
        </div>
    );
};

export default AllProducts;