import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../src/Images/banner/logo.jpg'
import {  FaFacebook, FaYoutubeSquare, FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa';




const Footer = () => {
  return (
    <footer className="footer p-10 bg-base-200 text-base-content">
      <div>
       <img className='w-[250px]' alt="" />
       <h2 className='text-2xl font-bold'>Coco Village BD</h2>
        <p className=''>From 2015 to Present<br /></p>
      </div>
      <div>
        <span className="footer-title">IMPORTANT</span>
        <Link to='/importance'>Terms And Condition</Link>
        <Link to='/faq' className="link link-hover">FAQ</Link>

      </div>
      <div>
        <span className="footer-title">INFORMATION</span>
        <Link to='/about' className="link link-hover">About Us</Link>
        <Link to='/contact' className="link link-hover">Contact Us</Link>
        <h2 >Email: <a href="mailto:cocovillagebd@gmail.com"> cocovillagebd@gmail.com</a></h2>
        <h2 >Factory & Office: 122/A,Munda, Uttarkhan, Dhaka-1230 Bangladesh</h2>
        <h2>Show Room: KA-1/2 Shop No#1,Nodda,Baridhara Gulshan-2 Dhaka</h2>

       

      </div>
      <div>
        <span className="footer-title flex">Social Links</span>
        <div className='flex mr-5'>
        <a href={`https://www.facebook.com/Cocovillagebd`} className="link link-hover text-4xl text-blue-500"><FaFacebook></FaFacebook></a>
        <a href={`https://www.instagram.com/cocovillagebd/`} className="link link-hover text-4xl text-pink-700"><FaInstagram></FaInstagram></a>
        <a href="/" className="link link-hover text-4xl "><FaTwitter></FaTwitter></a>
        <a href="/" className="link link-hover text-4xl text-blue-700"><FaLinkedinIn></FaLinkedinIn></a>

        <a href={`https://www.youtube.com/@1hometexbd139`} className="link link-hover text-red-600 text-4xl"><FaYoutubeSquare></FaYoutubeSquare></a>
        
        </div>
        
      </div>
    </footer>
  );
};

export default Footer;