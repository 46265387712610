import React from 'react';
import { Link } from 'react-router-dom';
import pic1 from '../../../Images/carusel/pic1.jpg'
import pic2 from '../../../Images/carusel/pic2.jpg'
import pic3 from '../../../Images/carusel/pic3.jpg'
import pic4 from '../../../Images/carusel/pic8.jpg'
import pic5 from '../../../Images/carusel/pic5.jpg'
import pic6 from '../../../Images/carusel/pic6.jpg'
import pic7 from '../../../Images/carusel/pic7.jpg'
import pic8 from '../../../Images/carusel/pic8.jpg'
import pic9 from '../../../Images/carusel/pic9.jpg'
import pic10 from '../../../Images/carusel/pic10.jpg'
import pic11 from '../../../Images/carusel/pic11.jpg'
import pic12 from '../../../Images/carusel/pic12.jpg'
import pic13 from '../../../Images/carusel/pic13.jpg'
import pic14 from '../../../Images/carusel/pic14.jpg'
import pic15 from '../../../Images/carusel/pic15.jpg'
import pic16 from '../../../Images/carusel/pic16.jpg'
import pic17 from '../../../Images/carusel/pic17.jpg'
import pic18 from '../../../Images/carusel/pic18.jpg'
import pic19 from '../../../Images/carusel/pic19.jpg'
import pic20 from '../../../Images/carusel/pic20.jpg'
import bed1 from '../../../Images/carusel/bed1.jpg'
import bed2 from '../../../Images/carusel/bed2.jpg'
import bed3 from '../../../Images/carusel/bed3.jpg'
import bed4 from '../../../Images/carusel/bed4.jpg'
import bed5 from '../../../Images/carusel/bed5.jpg'
import quality1 from '../../../Images/banner/quality1.png'
import quality2 from '../../../Images/banner/quality2.png'
import quality3 from '../../../Images/banner/quality3.png'
import "./Home.css"
import Carusel from './Carusel';
import Client from './Client';
import HomeProducts from './HomeProducts';

const Home = () => {
    return (
        <div className='mt-40' >
            {/* <div className="hero min-h-screen" style={{ backgroundImage: `url("https://img.freepik.com/premium-vector/online-shopping-concept-shopping-cart-with-bags-standing-upon-big-mobile-phone-flat-vector-design_196604-34.jpg?w=1380")` }}>
                <div className="hero-overlay bg-opacity-60"></div>
                <div className="hero-content text-center text-neutral-content">
                    <div className="max-w-md">
                        <h1 className="mb-5 text-5xl font-bold">Welcome !!</h1>
                        <p className="mb-5">WelCome to our official website. Here You can get all kinds of handicraft and Eco-friendly products and you can adorn your house attractively. </p>
                    </div>
                </div>      
            </div> */}
            <Carusel></Carusel>
            <div className='mx-auto'>
                <div className="hero-content mx-auto flex-col lg:flex-row">
                    <div className="hero min-h-screen bg-base-200">
                        <div className="hero-content flex-col lg:flex-row">
                            <img alt='' src="https://ecobanglajute.com/og_image/slider_30.jpg" className="lg:max-w-sm rounded-lg shadow-2xl" />
                            <div>
                                <h1 className="text-5xl font-bold">Welcome Native Buyers</h1>
                                <p className="py-6">Hey!! Welcome ,Here You can get all kind of native products which helps to decorate your house nicely and make your live charming.</p>
                                <button className="btn btn-primary"><Link to='/contact'> Click to contact</Link></button>
                            </div>
                        </div>
                    </div >
                    <div className="hero min-h-screen bg-base-200">
                        <div className="hero-content flex-col lg:flex-row-reverse">
                            <img alt='' src="https://thumbs.dreamstime.com/b/welcome-maid-promoter-seller-icon-element-hotel-pictogram-premium-quality-graphic-design-signs-symbols-collection-white-150066406.jpg" className=" lg:max-w-sm rounded-lg shadow-2xl" />
                            <div>
                                <h1 className="text-5xl font-bold">Welcome Foreign Buyers</h1>
                                <p className="py-6">Hey! Here you can get all types of garments products both home and office purposes.For contact please Click the button and give your information.Thanks</p>
                                <button className="btn btn-primary"><Link to='/contact'>Contact</Link></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HomeProducts></HomeProducts>
            <div className='m-10' >
                <h2 className='text-2xl font-bold text-center my-5'>COMPANY QUALITY</h2>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 m-10 text-center'>
                    <div className='mx-auto'>
                        <img src={quality1} alt="" />
                        <p>QUALITY PRODUCTS</p>
                    </div>
                    <div className='mx-auto'>
                        <img src={quality2} alt="" />
                        <p>100% COMPLIANCE</p>
                    </div>
                    <div className='mx-auto'>
                        <img src={quality3} alt="" />
                        <p>ON TIME DELIVERY</p>
                    </div>
                    
                </div>
                <p className='text-justify'>Coco Village BD is the best handicrafts manufacturing & exporting company in Bangladesh. Coco Village BD offers quality products, competitive prices and on-time delivery. Coco Village BD delivers high-quality, eco-friendly different types of Baskets, Laundry, Bags, Mats, Rugs, Poufs, Mirror, Macrame, Furniture, Wall Shelves and Trays in single and sets. We can use these different types of products in different places. Because of these things many forms of decoration benefit from it since it gives character and a touch of natural beauty.</p>

            </div>

            <div className='bg-lime-600 p-10'>
                <h2 className='text-2xl font-bold text-center my-5'>MOST POPULAR HANDICRAFT PRODUCTS</h2>

                <div className="carousel carousel-center max-w-md p-4 space-x-4 bg-neutral rounded-box mx-auto my-5">
                    <div className="carousel-item">
                        <img alt='' src={pic1} className="rounded-box w-3/4 h-1/2" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic2} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic3} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic4} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic5} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic6} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic7} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic8} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic9} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic10} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic11} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic12} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic13} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic14} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic15} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic16} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic17} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic18} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic19} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={pic20} className="rounded-box" />
                    </div>
                </div>
                <p className='text-justify'>Our most popular products are Seagrass basket, Seagrass bag, Seagrass floor mat, Seagrass placemat, Seagrass Tray, Seagrass oval cylinder, Seagrass pouf, Seagrass cylinder, Seagrass oval laundry basket, Seagrass laundry basket, Jute basket, Jute bag, Jute Cylinder, Jute laundry basket, Jute pouf, Jute macrame, Jute floor mat, Jute placemat, Jute rugs, Cotton rugs, Jhuta rugs, Kans grass basket, Palm fiber basket, Date leaf basket, Rattan Furniture, Rattan basket, Rattan mirror, Rattan Wall Shelves, Water hyacinth basket, Water hyacinth Placemat, etc.
                </p>
                <h2 className='text-2xl font-bold text-center my-5'>MOST POPULAR BED LINE</h2>

                <div className="carousel carousel-center max-w-md p-4 space-x-4 bg-neutral rounded-box mx-auto my-5">
                    <div className="carousel-item">
                        <img alt='' src={bed1} className="rounded-box " />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={bed2} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={bed3} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={bed4} className="rounded-box" />
                    </div>
                    <div className="carousel-item">
                        <img alt='' src={bed5} className="rounded-box" />
                    </div>
                </div>
                <p>Article: Bed sheet, Fitted sheet, Quilt cover, Pillow case, Curtain, Comforter, Bed spread, Cushion etc.

                    Fabric Quality: TC120 – TC400.

                    Blend: 100% cotton, 50/50 poly cotton, 70/30 poly cotton, CVC, Sateen, Percale, Twill, Half panama, Flannel etc.

                    Fabric print (Pigment, Reactive): Rotary print in 20 colors with 640mm, 812mm and 914mm repeat designs. Flat bed designs print in 7 colors in wider width screen.

                    Solid color (Pigment, Reactive & Vat) : Thermo sol / Pad Steam dyeing in light, medium and dark shades.</p>
            </div>
            <Client></Client>

        </div>
    );
};

export default Home;