import React, { createContext, useEffect, useState } from 'react';
import {createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, } from 'firebase/auth'
import app from '../../firebase/firebase.config';
import { current } from 'daisyui/src/colors';

export const AuthContext=createContext();
const  auth=getAuth(app)


const Authprovider = ({children}) => {
const [user,setUser]=useState(null);
const [loading,setLoading]=useState(true)


 const createUser=(email,password)=>{
    return createUserWithEmailAndPassword(auth,email,password)
}
const signInUser=(email,password)=>{
     return signInWithEmailAndPassword(auth,email,password)
}

const logOut=(auth)=>{
    return signOut(auth)
}

useEffect(()=>{
    const unsubscribe=onAuthStateChanged(auth,currentUser=>{
        console.log(currentUser);
        setUser(currentUser)
    })
    return ()=>{
        return unsubscribe
    }
},[])


const authInfo={
    user,
    loading,
    createUser,
    signInUser,
    logOut




}

    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    );
};

export default Authprovider;