import React from 'react';

const FAQ = () => {
    return (
        <div className='m-10'>
            <h2 className='text-2xl mx-auto text-center my-10'>GENERAL QUESTION</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 '>
                <div >
                    <div tabIndex={0} className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box">
                        <div className="collapse-title text-xl font-medium">
                            <p>What is your monthly production capacity?</p>
                        </div>
                        <div className="collapse-content">
                            <p>Production Capacity/Month:10X20'HQ.Containers or in Pcs</p>
                        </div>
                    </div>
                    <div tabIndex={0} className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box">
                        <div className="collapse-title text-xl font-medium">
                            <p>What is your Production Lead Time?</p>
                        </div>
                        <div className="collapse-content">
                            <p>Production Lead Time:75-90 Days</p>
                        </div>
                    </div>
                    <div tabIndex={0} className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box">
                        <div className="collapse-title text-xl font-medium">
                            <p>What is your Shipment Term?</p>
                        </div>
                        <div className="collapse-content">
                            <p>Shipment Term :FOB</p>
                        </div>
                    </div>
                    <div tabIndex={0} className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box">
                        <div className="collapse-title text-xl font-medium">
                            <p>What are your Payment Terms?</p>
                        </div>
                        <div className="collapse-content">
                            <p>Payment Terms:LC@Slight or TT(30% deposit & 70% against scan copy of shipping documents).</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div tabIndex={0} className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box">
                        <div className="collapse-title text-xl font-medium">
                            <p>What is your Port Name ?</p>
                        </div>
                        <div className="collapse-content">
                            <p>FOB Port: Chittagong,Bangladesh</p>
                        </div>
                    </div>
                    <div tabIndex={0} className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box">
                        <div className="collapse-title text-xl font-medium">
                            <p>What is your Payment Currency?</p>
                        </div>
                        <div className="collapse-content">
                            <p>Currency:USD</p>
                        </div>
                    </div>
                    <div tabIndex={0} className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box">
                        <div className="collapse-title text-xl font-medium">
                            <p>?</p>
                        </div>
                        <div className="collapse-content">
                            <p>Collect from Destination</p>
                        </div>
                    </div>
                    <div tabIndex={0} className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box">
                        <div className="collapse-title text-xl font-medium">
                            <p>What is your HS Code?</p>
                        </div>
                        <div className="collapse-content">
                            <p>HS Code:</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default FAQ;