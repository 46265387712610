import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <div className='bg-sky-500/[.06] p-10'>
            <div className='m-5 md:m-10 lg:m-20 text-center '>
                <FontAwesomeIcon icon="fa-solid fa-phone" />
                <h2 className='font-bold text-xl my-10'><u>VISION STATEMENT</u></h2>
                <p className='text-justify'>Coco Village BD  Group has been established for nearly 12 years. Having the industrial knowledge of over 15 years it. Sister concern companies namely Coco Village BD BD co. One Hometex, Coco Village BD  Interior Design ,Coco Village BD Fabric Limited, Coco Village BD Leather . IT & Graphics  and None profitable Organization Accord Foundation Bangladesh . Coco Village BD has focused on its business and successfully established the name as a world-class Garments and handcuffs bed Line  Manufacturer under one roof having a “pick n' pay” facility of Knit and Woven denim bed line  based Garments making it a right choice for one stop solution for all kinds of Men's Garments.

                    We keep improving the quality of our products and services and looking forward to introduce a wider range of products to our customers to meet their needs. Given the strength of our leading position in the National Whole Sale market, with a comprehensive product portfolio, reputable & strong customer base, the aim is to consolidate our position as one of the best Whole Sale Garments manufacturer in Bangladesh. We will continue to focus on strengthening our business foundation to ensure a strong base on which we can rely to explore new business opportunities for the development of our core business, broaden our revenue base, increase the growth and potential and thus enhance the customer satisfaction.

                    We invite you to explore the Fashion home goods handicrafts  world of Coco Village BD.</p>
            </div>
            <div className='m-5 md:m-10 lg:m-20 text-center'>
                <h2 className='font-bold text-xl my-10'><u>MISSION </u></h2>
                <p className='text-justify'>To accomplish this vision into a reality we have set up a mission. We are expanding our operation network all over Bangladesh where the skill labors are easily available and train them as skilled artisans. The necessary frame work is in process & result is expected to come in due course under intensive training and supervision. We believe that our mission is achievable as long as we work with honesty and integrity.</p>
            </div>
            <div className='m-5 md:m-10 lg:m-20 text-center'>
                <h2 className='font-bold text-xl my-10'><u>MISSION STATEMENT</u></h2>
                <p className='text-justify'>Our main target is to achieve the best Local Brand Ranking with a consistent better quality at Reasonable Price. Coco Village BD  group is specialized in Quality Services. Our vision is exceptional from other and one that combines creativity with the latest research and development in ready-made garments & hometex handcrafts product in the world. We are very customer-oriented company, putting our customers first and always focusing on gaining and deserving the trust of every single one of our customers. So, we listening to our customers, staying at the cutting edge of the latest trends in fashion, home goods jute goods  and constantly developing our better services which enable us to fulfil this vision better and better every day. Then we are being the number One Clothing Brand.</p>
            </div>
            <div className='m-5 md:m-10 lg:m-20 text-center'>
                <h2 className='font-bold text-xl my-10'><u>CONTEXTUAL</u></h2>
                <p className='text-justify'>Coco Village BD works with the objective of creating employment opportunity for the jobless
                    people in Bangladesh using the local material and skills. Our goal is to connect the
                    non-traditional handicrafts items into the world market by participating in different
                    international trade fairs. We have good work force that are skilled and professional.
                    They have been working round the clock to meet the buyer's requirements and keep in touch
                    with the world's trend as it is a very trendy sector. We always invite innovative
                    ideas and try to incorporate those in our product range. Our strength is a large number
                    of skilled manpower in Bangladesh who desperately needs job for a good living. We believe
                    that quality products delivery, competitive prices, on time delivery and sound understanding
                    between buyers and sellers may lead to a sustainable business. There are around 20,000 people
                    who have been working with Coco Village BD now (directly & indirectly).</p>
            </div>
            <div className='m-5 md:m-10 lg:m-20 text-center'>
                <h2 className='font-bold text-xl my-10'><u>Business Profile</u></h2>
                <p className='text-justify'>Coco Village BD is a joint corporation involved in the local and global trade of products supporting a sustainable nature environment ,in other words eco-friendly products.Eco friendly Products
                    refer to a variety of goods and services that cause little or no harm to the natural environment in the process of being produced,distributed ,stored or sold.
                    <br />
                    We,at Coco Village BD are working to brand the eco-friendly products of bangladesh through an inclusive business model while strengthening the local
                    manufactures and building their capacity . <br />
                    The SEMs of Bangladesh are endangered by the threats of financial insolvency ,low productivity ,quality issues and lake of market access.We intend to bring the SMEs closer to the market to empower them to survive and grow in the long run. <br />
                    We sharpen our expertise in innovative usage of jute diversified products as a tool of marketing  branding and promotion .Our jute bags can carry your slogan and enhance your image
                    as a business concerned about the welfare of the environment.
                </p>

                <p> </p>
            </div>
            <div className='m-5 md:m-10 lg:m-20 text-center'>
                <h2 className='font-bold text-xl my-10'><u>Current Capacity</u></h2>
                <p className='text-justify'> We,Coco Village BD , are currently holding a supply portfolio of 20 SMEs who have expertise in various jute diversified ranging from basic jute shopping bags to highly value added decorative items like bed line ,approval fashion accessories.
                    <br />
                    Our business strength lies in a proper delegation of working among associate SEMs and getting quality output through proper monitoring and capacity building exercises.Currently ,
                    <p className=' text-blue-600'> We hold a  capacity of up to 1,00,000 pcs jute bags per month, cushion cover 1,00,000 pcs per month,inner cushion 80,000 per month,inner pillow 50,000 pcs per month, curtain 20,000 pcs per month ,comfort 30,000 pcs per month,Quilt cover 20,000 per month,Table cloth,face towel,Hand towel,Bath towel,Bath Rob mens,Baby Bath Rob etc.
                    </p>
                </p>
            </div>
            <div className='m-5 md:m-10 lg:m-20 text-center'>
                <h2 className='font-bold text-xl my-10'><u>Portfolio of Business</u></h2>
                <p className='text-justify'>Coco Village BD had catered to numerous local businesses with exciting environment-friendly jute diversified products .Some of the most perstigious clients include Hotel Pan Pacific Sonargaon,Hotel Artisan,Hotel Seagull,Hotel Sea Place,Mir Telecom,Brac University ,Sadakalo Fashion House ,Katalyst,EON Geoup,Brothers Furniture ,Hatil,Otobi,Fishtech,Square Pharmaceuticals,etc.
                    <br />
                    Our proven vigor in catering our client has given us a position of confidence.Taking our jute bags to our linkages in export market,we have caered jute bags importers in Uk, Dubai,and Denmark.Our nature-friendly products have begun to claims their places in the international market.
                </p>
            </div>
            <div className='m-5 md:m-10 lg:m-20 text-center'>
                <h2 className='font-bold text-xl my-10'><u>Machine Capacity</u></h2>
                <p className='text-justify'>We,at Coco Village BD ,are continuing our producing with various machines like.
                    <br />
                    <br />
                    1.Swing Machine(JUKI) 30 pcs. <br />
                    2.Swing Machine (INDUSTRIAl) 3 pcs <br />
                    3.Cutting Machine 1 pcs <br />
                    4.Tuni dile machine 2 pcs <br />
                    5.Zig Zag machine 50 pcs <br />
                    6.Roland parva bi-color 1 pcs <br />
                    7.Roland ParvaSingle color 1 pcs <br />
                    8.Roland Favorit Single color 1 pcs <br />
                    9.Polar Cutting machine 1 pcs <br />
                    10.Eyelet Machine 1 pcs <br />
                    11.Eyelet hole Machine 1 pcs <br />
                    12.Bartacking Machine 1 pcs <br />
                    13.Button Machine 1 pcs <br />
                    14.pillow filling Machine 1 pcs <br />
                    15.Vacuum Machine 1 pcs

                </p>
            </div>
            <div className='m-5 md:m-10 lg:m-20 text-center'>
                <h2 className='font-bold text-xl my-10'><u>Capacity Of Production</u></h2>
                <p className='text-justify'>We ,Coco Village BD has a remarkable potentiality to produce huge products in every categories ,because Coco Village BD is working with 20 Small Medium enterprise who have expertise in various jute products .The following is the capacity of Coco Village BD in production of Jute Diversified Products.
                    <br />
                    <br />
                    1.jute bags 1,00,000 pcs per month <br />
                    2.Cushion cover 1,00,000 pcs per month <br />
                    3.Inner Pillow 50,000 pcs per month <br />
                    4.Inner Cushion 150,000 pcs per month <br />
                    5.Curtain 20,000 pcs per month <br />
                    6.Comfort 20,000 pcs per month <br />
                    7.Quilt Cover 20,000 pcs per month <br />
                    8.Table Cloth 10,000 pcs per month <br />
                    9.Jute basket 20,000 pcs per month <br />
                    10.Jute Place Mat 20,000 pcs per month <br />
                    11.Floor Mat 15,000 pcs per month <br />
                    12.Jute Table Runner 5,000 pcs per month <br />
                    13.Jute Tote Vag 50,000 pcs per month <br />
                    14.Jute Poufs 10,000 pcs per month <br />
                    15.Seagrass Basket,Bags,Floor mats,Place mats,trays 1,00,000 pcs per month <br />
                    16.Data leaf Basket,Place Mats,Trays 50,000 pcs per month <br />
                    17.Kans Grass Baskets,Place mats ,Trays 50,000 pcs per month

                </p>
            </div>
            <div className='m-5 md:m-10 lg:m-20 text-center'>
                <h2 className='font-bold text-xl my-10'><u>Future Plans</u></h2>
                <p className='text-justify'>We, at Coco Village BD ,look forward to expanding in the global arena of eco-friendly products from Bangladesh.To address the global concern of environment consciousness .Coco Village BD is thinking to step forward to set up buying house activities. <br />
                    For mas reaching of Eco-friendly concern in the Bangladesh ;Coco Village BD has a plan to open several outlets among the cities of Bangladesh.The outlets will be selling exclusively Bangladesh made eco-friendly products. <br />
                    It will be also introduce its own designer lines to serve the consumers of trendy lifestyle products.Coco Village BD also plan to distribute its eco-friendly products through various well established retail chain and superstore chains and also by business to business linkages through intermediates in abroad.
                </p>
            </div>
            <div className='m-5 md:m-10 lg:m-20 text-center'>
                <h2 className='font-bold text-xl my-10'><u>Our Services</u></h2>
                <p className='text-justify'>We do; <br /> <br />
                    1.customized bags(made of jute,leather,rexine,PU and others) <br />
                    2.Exclusive corporate gift items <br />
                    3.Customized file folder (made of jute ,leather,rexine,PU and other) <br />
                    4.T-shirt,5.Polo shirt <br />
                    5.Huddy,Uniform <br />
                    6.Sportswear <br />
                    7.Home Textile (like cushion cover ,curtain ,runner,place mat,napkin) <br />
                    8.Any kind of printing items
                </p>
            </div>

            <div className='mx-auto text-center mb-10'>
                <h2 className='text 2xl m-auto'>TECHNICAL INFORMATION</h2>
                <div className="overflow-x-auto">
                    <table className="table table-zebra w-full">
                        {/* head */}
                        <thead>
                            <tr>

                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* row 1 */}
                            <tr>
                                <td>Office Staff</td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>Workers</td>
                                <td>1200</td>
                            </tr>
                            {/* row 2 */}
                            <tr>
                                <td>Product Lead Time</td>
                                <td>75-90 days</td>
                            </tr>
                            {/* row 3 */}
                            <tr>
                                <td>Payment Term</td>
                                <td>L/C@Slight or TT(30% Deposit adn 70% against copy of shipping documents)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <h2 className='text-2xl'>Please feel free to contact us @ <Link className='text-red-600' to='/contact'> The Contact Page</Link></h2>

        </div>
    );
};

export default About;