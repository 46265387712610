import React from 'react';
import grass1 from '../../Images/Products/seagrass/grass1.jpg'
import grass2 from '../../Images/Products/seagrass/grass2.jpg'
import grass3 from '../../Images/Products/seagrass/grass3.jpg'
import grass4 from '../../Images/Products/seagrass/grass4.jpg'
import grass5 from '../../Images/Products/seagrass/grass5.jpg'
import grass6 from '../../Images/Products/seagrass/grass6.jpg'
import grass7 from '../../Images/Products/seagrass/grass7.jpg'
import grass8 from '../../Images/Products/seagrass/grass8.jpg'
import grass9 from '../../Images/Products/seagrass/grass9.jpg'
import ProductCard from './ProductCard';
const SeaGrass = () => {
    const seaGrasses=[
        {
            id:'1',
            name:'leafy',
            img:grass1
        },
        {
            id:'2',
            name:'Seagrass basket',
            img:grass2
        },
        {
            id:'3',
            name:'Landi basket',
            img:grass3
        },
        {
            id:'4',
            name:'Seagrass basket',
            img:grass4
        },
        {
            id:'5',
            name:'kans Grass',
            img:grass5
        },
        {
            id:'6',
            name:'Kans Grass',
            img:grass6
        },
        {
            id:'7',
            name:'leafy basket',
            img:grass7
        },
        {
            id:'8',
            name:'Landi basket',
            img:grass8
        },
        {
            id:'9',
            name:'Seagrass Basket',
            img:grass9
        },
    ]
    return (
        <div className='mt-40 mx-auto'>
            <h2 className='text-3xl text-center font-bold'>PRODUCTS GALLERY</h2>
                <h3 className='text-2xl m-10 font-bold'>Seagrass Products</h3>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 m-10 gap-10'>
                    {
                        seaGrasses.map(seaGrass=><ProductCard 
                        key={seaGrass.id}
                        juteProduct={seaGrass}
                        ></ProductCard>)
                    }
                </div>
            
        </div>
    );
};

export default SeaGrass;