import React from 'react';

const HomeProduct = ({homeProduct}) => {
    const {image}=homeProduct;
    return (
        <div className='m-10'>
                <div>
                    <img className='h-[350px] w-[100%]' src={image} alt="" />
                </div>
                <h2 className='text-center text-2xl font-bold '>{ }</h2>


            </div>
    );
};

export default HomeProduct;