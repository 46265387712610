import React from 'react';

const TermsAndCondition = () => {
    return (
        <div className='m-10'>
            <h3 className='text-2xl text-center font-bold'>TERMS AND CONDITION</h3>
            <div className='my-5'>
                <p> <span className='text-xl font-bold py-5'>Acceptance of Terms:</span> By using this website, you agree to be bound by these Terms & Conditions. If you do not agree to these terms, please do not use this website.</p>
            </div>
            <div className='my-5'>
                <p> <span className='text-xl font-bold'>Modifications to Terms:</span> We reserve the right to modify these terms at any time. Your continued use of this website following any such modification constitutes your acceptance of the new terms.</p>
            </div>
            <div className='my-5'>
                <p> <span className='text-xl font-bold'>Use of Content:</span> All text, images, graphics, and other content on this website are the property of [website owner] and are protected by copyright law. You may not use this content for commercial purposes without written permission from [website owner].</p>
            </div>
            <div className='my-5'>
                <p> <span className='text-xl font-bold'>User Conduct:</span> You agree to use this website only for lawful purposes and to not engage in any activity that might harm or offend others.</p>
            </div>
            <div className='my-5'>
                <p> <span className='text-xl font-bold'>Disclaimers:</span> This website is provided on an "as is" basis without any warranties of any kind, either expressed or implied. [website owner] does not guarantee the accuracy or completeness of the information on this website and will not be held responsible for any errors or omissions.</p>
            </div>
            <div className='my-5'>
                <p> <span className='text-xl font-bold'>Limitation of Liability:</span> [website owner] will not be liable for any damages arising from the use of this website, including but not limited to direct, indirect, incidental, and punitive damages.</p>
            </div>
            <div className='my-5'>
                <p> <span className='text-xl font-bold'>Indemnification:</span> You agree to indemnify and hold harmless [website owner] from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of this website.</p>
            </div>
            <div className='my-5'>
                <p> <span className='text-xl font-bold'>Governing Law:</span> These terms and conditions will be governed by and construed in accordance with the laws of [insert state/country].</p>
            </div>
            <div className='my-5'>
                <p> <span className='text-xl font-bold'>Severability: </span> If any provision of these terms is found to be invalid, the remaining provisions shall remain in full force and effect.</p>
            </div>
            <div className='my-5'>
                <p> <span className='text-xl font-bold'>Contact Information:</span> If you have any questions about these terms, please contact [website owner].</p>
            </div>
        </div>
    );
};

export default TermsAndCondition;