import React from 'react';
import client1 from '../../../Images/client/client1.jpg'
import client2 from '../../../Images/client/client2.jpg'
import client3 from '../../../Images/client/client3.jpg'
import client4 from '../../../Images/client/client4.jpg'
import client5 from '../../../Images/client/client5.jpg'
import client6 from '../../../Images/client/client6.jpg'
import client7 from '../../../Images/client/client7.jpg'
import client8 from '../../../Images/client/client8.jpg'
import client9 from '../../../Images/client/client9.jpg'
import client10 from '../../../Images/client/client10.jpg'
import client11 from '../../../Images/client/client11.jpg'
import client12 from '../../../Images/client/client12.jpg'
import client13 from '../../../Images/client/client13.jpg'
import client14 from '../../../Images/client/client14.jpg'
import client15 from '../../../Images/client/client15.jpg'
import client16 from '../../../Images/client/client16.jpg'

const Client = () => {
    return (
        <div className='my-10'>
            <h3 className='text-2xl font-bold text-center my-5'>OUR CLIENTS</h3>
            <div className="carousel carousel-end rounded-box">
                <div className="carousel-item">
                    <img src={client1} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client2} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client3} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client4} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client5} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client6} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client7} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client8} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client9} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client10} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client11} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client12} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client13} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client14} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client15} alt="Drink" />
                </div>
                <div className="carousel-item">
                    <img src={client16} alt="Drink" />
                </div>
            </div>
        </div>
    );
};

export default Client;