import React from 'react';
import bedding1 from '../../Images/Products/bedding/bedding1.jpg'
import bedding2 from '../../Images/Products/bedding/bedding2.jpg'
import bedding3 from '../../Images/Products/bedding/bedding3.jpg'
import bedding4 from '../../Images/Products/bedding/bedding4.jpg'
import bedding5 from '../../Images/Products/bedding/bedding5.jpg'
import bedding6 from '../../Images/Products/bedding/bedding6.jpg'
import bedding7 from '../../Images/Products/bedding/bedding7.jpg'
import bedding8 from '../../Images/Products/bedding/bedding8.jpg'
import bedding9 from '../../Images/Products/bedding/bedding9.jpg'
import bedding10 from '../../Images/Products/bedding/bedding10.jpg'
import bedding11 from '../../Images/Products/bedding/bedding11.jpg'
import bedding12 from '../../Images/Products/bedding/bedding12.jpg'
import bedding13 from '../../Images/Products/bedding/bedding13.jpg'
import bedding14 from '../../Images/Products/bedding/bedding14.jpg'
import bedding15 from '../../Images/Products/bedding/bedding15.jpg'
import bedding16 from '../../Images/Products/bedding/bedding16.jpg'
import bedding17 from '../../Images/Products/bedding/bedding17.jpg'
import bedding18 from '../../Images/Products/bedding/bedding18.jpg'
import ProductCard from './ProductCard';

const Beddings = () => {
    const beddings=[
        {
            id:'1',
            name:'Hotel Line',
            img:bedding1
        },
        {
            id:'2',
            name:'Hotel Line',
            img:bedding2
        },
        {
            id:'3',
            name:'Tufted Cushion Cover',
            img:bedding3
        },
        {
            id:'4',
            name:'Towel',
            img:bedding4
        },
        {
            id:'5',
            name:'bedding Sheet',
            img:bedding5
        },
        {
            id:'6',
            name:'Chair pad',
            img:bedding6
        },
        
        {
            id:'7',
            name:'Tufted Pillow Cover',
            img:bedding7
        },
        {
            id:'8',
            name:'Cushion Cover',
            img:bedding8
        },
        {
            id:'9',
            name:'Stipe Satin Pillow',
            img:bedding9
        },
        {
            id:'10',
            name:'Comforter',
            img:bedding10
        },
        {
            id:'11',
            name:'Towel',
            img:bedding11
        },
        {
            id:'12',
            name:'Ambos Towel',
            img:bedding12
        },
        {
            id:'13',
            name:'Bath rob',
            img:bedding13
        },
        {
            id:'14',
            name:'Chair Pad',
            img:bedding14
        },
        {
            id:'15',
            name:'Chair Pad',
            img:bedding15
        },
        {
            id:'16',
            name:'Cooking Dress',
            img:bedding16
        },
        {
            id:'17',
            name:'Hospital bed',
            img:bedding17
        },
        {
            id:'18',
            name:' Basket',
            img:bedding18
        },
    ]
    return (
        <div className='mt-40 mx-auto'>
            <h2 className='text-3xl text-center font-bold'>PRODUCTS GALLERY</h2>
                <h3 className='text-2xl m-10 font-bold'>Bedding Products</h3>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 m-10 gap-10'>
                    {
                        beddings.map(bedding=><ProductCard 
                        key={bedding.id}
                        juteProduct={bedding}
                        ></ProductCard>)
                    }
                </div>
            
        </div>
    );
};

export default Beddings;